import {request} from '../../util/Request'

const STATUS_FORM_STATE = {
    id: null,
    status: 8,
    note: null,
}

export default {
    data() {
        return {
            statusFormVisible: false,
            statusFormState: {...STATUS_FORM_STATE},
        }
    },
    methods: {
        async handleStatusUpdateSubmit() {
            try {
                const response = await request({
                    url: 'orders/update/status',
                    method: 'post',
                    data: {...this.statusFormState},
                })

                this.itemUpdated()
                this.handleStatusFormOperationClose()
            } catch (error) {
                this.handleServerError(error)
            }
        },
        handleStatusFormOperationClose(refreshList = true) {
            this.statusFormState = {...STATUS_FORM_STATE}
            this.statusFormVisible = false

            if (refreshList) {
                this.refreshList()
            }
        },
        handleStatusUpdateClick(item) {
            this.statusFormState.id = item.id;
            this.statusFormState.status = item.load_status;
            this.statusFormVisible = true;
        },
    }
}
