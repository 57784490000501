import {request} from '../../../util/Request'
import Error from "../../../util/Error";

const CTC_LOAD_DOCUMENT_STATE = {
    token: null,
    id: null,
    document: null,
    title: null,
    error: false
};

const DEFAULT_CTC_ORDER_STATE = {
    supplier_carrier_id: null,
    supplier_compound_id: null,
    price_period_id: null,
    route_id: null,
    price_id: null,
    loading_factors: 0,
    loading_factor_note: 0,
    document: {...CTC_LOAD_DOCUMENT_STATE},
    documents: [],
    ids: [],
    errors: null,
    comments: null,
}

const DEFAULT_FORM_STATE = {
    orders: [], // DEFAULT_CTC_ORDER_STATE
}

export default {
    data() {
        return {
            ctcSelectedOrders: [],
            groupedCtcOrders: [],
            selectedCtcOrderLength: 0,
            ctcLoadFormState: {...DEFAULT_FORM_STATE},
            dropdowns: {
                carrierSuppliers: [],
                compoundSuppliers: [],
            },
        }
    },
    methods: {
        groupCtcLoad() {
            const self = this
            let from_to_keys = [] // array of from location id and to Location ids
            _.map(self.ctcSelectedOrders, (item) => {
                from_to_keys.push(`${item.from_location.id} ${item.to_location.id} ${item.supplier_compound.id}`)
            })

            _.map(_.uniqBy(from_to_keys), (item, index) => {
                const locations = item.split(' ')
                const items = _.filter(self.ctcSelectedOrders, (order) => (
                    order.from_location.id === locations[0] &&
                    order.to_location.id === locations[1] &&
                    order.supplier_compound.id === locations[2]
                ))

                self.groupedCtcOrders.push({
                    from_location: items[0].from_location,
                    to_location: items[0].to_location,
                    supplier_compound: items[0].supplier_compound,
                    from_supplier_compound: items[0].from_supplier_compound,
                    items: items,
                    does_m_l_approval_required: (_.filter(items, (o) => o.does_m_l_approval_required > 0).length > 0),
                    models: _.uniqBy(items, 'model.id').map(i => i.model.id),
                    brands: _.uniqBy(items, 'brand.id').map(i => i.brand.id),
                })

                self.ctcLoadFormState.orders.push({
                    ids: items.map((order) => order.id), // OrderIds
                    supplier_carrier_id: null,
                    supplier_compound_id: locations[2],
                    from_location_id: locations[0],
                    to_location_id: locations[1],
                    price_id: null,
                    route_id: null,
                    loading_factors: 0,
                    errors: '',
                    loading_factor_note: null,
                    document: {...CTC_LOAD_DOCUMENT_STATE},
                    documents: [],
                    comments: null,
                    truck_load_type: 'ftl',
                })
            })

            this.getCtcOrderNearLocationSupplierCarrier()
            this.ctcLoadFormVisible = true
        },
        async handleCtcLoadSubmit() {
            if(this.validateCtcLoad()) {
                return;
            }

            try {
                const response = await request({
                    url: 'inbound/loads/create',
                    method: 'post',
                    data: {...this.ctcLoadFormState, is_cc: 1},
                })

                this.loadCreateSuccess()
                this.handleCtcLoadFormOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.validationError()
                    return false
                }

                this.handleServerError(error)
            }
        },
        async getCtcOrderNearLocationSupplierCarrier() {
            try {
                const response = await request({
                    url: '/dropdowns/orders/matched/location/carrier/suppliers',
                    method: "post",
                    data: {
                        orders: this.ctcSelectedOrders.map(item => item.id),
                        is_ctc: 1
                    }
                })

                const {data} = response
                this.dropdowns.carrierSuppliers = data
                _.map(this.groupedCtcOrders, (group, index) => {
                    const filtered = _.filter(data, (supplier) => (
                            _.filter(supplier.prices, ({route}) => (
                                    route.from_location.id === group.from_location.id &&
                                    route.to_location.id === group.to_location.id
                                )
                            ).length > 0
                        )
                    )

                    if(filtered.length > 0) {
                        this.ctcLoadFormState.orders[index].supplier_carrier_id = filtered[0].id
                    }
                })
            } catch (e) {
                this.dropdowns.carrierSuppliers = []
            }
        },
        async handleCtcLoadAddClick() {
            _.map(this.ctcSelectedOrders, (item, index) => {
                this.ctcSelectedOrders[index].from_supplier_compound = item.supplier_compound;
                this.ctcSelectedOrders[index].supplier_compound = null;
                this.ctcSelectedOrders[index].supplier_compound_id = null;
            })

            try {
                const response = await request({
                    url: '/dropdowns/orders/drafts/matched/location/compound/suppliers',
                    method: "post",
                    data: {
                        orders: this.ctcSelectedOrders.map(item => item.id)
                    }
                })

                const {data} = response
                this.dropdowns.compoundSuppliers = data
                _.map(data, (item) => {
                    const index = _.findIndex(this.ctcSelectedOrders, {id: item.id})
                    this.ctcSelectedOrders[index].supplier_compound = item.supplier_compound;
                    this.ctcSelectedOrders[index].supplier_compound_id = item.supplier_compound.id;
                })

                this.groupCtcLoad();
            } catch (e) {
                this.dropdowns.compoundSuppliers = []
            }
        },
        validateCtcLoad() {
            let errors = false

            _.map(this.ctcLoadFormState.orders, (item, index) => {
                this.ctcLoadFormState.orders[index].errors = '';
                if(_.isEmpty(item.supplier_carrier_id) || _.isEmpty(item.supplier_compound_id)) {
                    errors = true
                    this.ctcLoadFormState.orders[index].errors = this.$t('msc.requiredFieldsMissing')+'<br>'
                }

                if(item.loading_factors <= 0) {
                    errors = true
                    this.ctcLoadFormState.orders[index].errors = this.ctcLoadFormState.orders[index].errors + this.$t('validation.loadingFactorInvalid')
                }
            })

            return errors;
        },
        handleCtcRowSelection(item, rowIndex, event) {
            let response = true;
            if (item.load_status === 8 && item.tr_status === 3 && item.tr_status_type === 'cc' && (item.client_tr_at && item.client_tr_accept_at)) {
                const index = _.findIndex(this.ctcSelectedOrders, {id: item.id});
                if (index >= 0) { // if already exist then delete
                    setTimeout(() => this.$refs.orderTable.unselectRow(rowIndex), 200)
                    delete this.ctcSelectedOrders[index]
                    this.selectedCtcOrderLength = this.selectedCtcOrderLength - 1
                } else { // else add new
                    this.ctcSelectedOrders.push(item)
                    setTimeout(() => this.$refs.orderTable.selectRow(rowIndex), 200)
                    this.selectedCtcOrderLength = this.selectedCtcOrderLength + 1
                }
            } else {
                response = false
            }

            this.ctcSelectedOrders = _.compact(this.ctcSelectedOrders)
            return response;
        },
        handleCtcLoadFormOperationClose(refreshList = true) {
            this.ctcLoadFormState = {...DEFAULT_FORM_STATE}
            this.ctcLoadFormState.orders.length = 0
            this.ctcLoadFormVisible = false
            this.groupedCtcOrders.length = 0
            this.ctcSelectedOrders.length = 0
            this.selectedCtcOrderLength = 0
            this.$refs.orderTable.clearSelected()
            this.formErrors = new Error({})

            if(refreshList) { // to handle call from refreshList (When page change)
                this.refreshList()
            }
        },
        handleSupplierChange(value, group, index) {
            const {from_location, to_location, brands: groupBrands, models: groupModels} = group
            const {prices} = _.find(this.dropdowns.carrierSuppliers, {id: value})
            this.ctcLoadFormState.orders[index].loading_factor_note = null
            const price = _.find(prices, ({brands, route, models}) => {
                let doesSelectedOrderContainsPrice = 0
                let doesSelectedOrderContainsPriceBrand = 0

                _.map(models, (m) => {
                    if (groupModels.includes(m.id)) {
                        doesSelectedOrderContainsPrice++;
                    }
                })

                _.map(brands, (m) => {
                    if (groupBrands.includes(m.id)) {
                        doesSelectedOrderContainsPriceBrand++;
                    }
                })

                return (
                    route.from_location.id === from_location.id &&
                    route.to_location.id === to_location.id &&
                    groupModels.length === doesSelectedOrderContainsPrice &&
                    groupBrands.length === doesSelectedOrderContainsPriceBrand
                )
            });

            if (price) {
                this.ctcLoadFormState.orders[index].route_id = price.route.id
                this.ctcLoadFormState.orders[index].price_id = price.id
                this.ctcLoadFormState.orders[index].loading_factors = price.loading_factors
                this.ctcLoadFormState.orders[index].price_period_id = price.price_periods[0].id
                if (price.loading_factors > 0 && group.items.length > price.loading_factors) {
                    this.ctcLoadFormState.orders[index].loading_factor_note = (Math.ceil(group.items.length / price.loading_factors)) + ' ' + this.$t('msc.loadsWillBeCreated')
                }
            }else{
                this.ctcLoadFormState.orders[index].route_id = null
                this.ctcLoadFormState.orders[index].price_id = null
                this.ctcLoadFormState.orders[index].price_period_id = null
            }
        },
        handleAddUpdateCtcLoadDocumentClick(groupIndex) {
            this.ctcLoadFormState.orders[groupIndex].document.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.ctcLoadFormState.orders[groupIndex].document.document || this.ctcLoadFormState.orders[groupIndex].document.document.length <= 0 || !this.ctcLoadFormState.orders[groupIndex].document.document.id) {
                this.ctcLoadFormState.orders[groupIndex].document.error = true;
                errors.document = [this.$t('validation.required')];
            }

            if (!this.ctcLoadFormState.orders[groupIndex].document.title || _.trim(this.ctcLoadFormState.orders[groupIndex].document.title).length <= 2) {
                this.ctcLoadFormState.orders[groupIndex].document.error = true;
                errors.title = [this.$t('validation.required')];
            }

            _.map(this.ctcLoadFormState.orders[groupIndex].documents, (document) => {
                if(document.token !== this.ctcLoadFormState.orders[groupIndex].document.token) {
                    if(document.title === this.ctcLoadFormState.orders[groupIndex].document.title) {
                        errors.title = [this.$t('validation.duplicate')];
                        this.ctcLoadFormState.orders[groupIndex].document.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if(this.ctcLoadFormState.orders[groupIndex].document.error) return false;

            const entity = this.ctcLoadFormState.orders[groupIndex].document;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.ctcLoadFormState.orders[groupIndex].documents, {token: entity.token});

            if (this.ctcLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.ctcLoadFormState.orders[groupIndex].documents[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                this.ctcLoadFormState.orders[groupIndex].documents.push(entity)
            }

            this.ctcLoadFormState.orders[groupIndex].document = {...CTC_LOAD_DOCUMENT_STATE, token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}
        },
        handleDeleteCtcLoadDocumentClick(groupIndex, token) {
            const index = _.findIndex(this.ctcLoadFormState.orders[groupIndex].documents, {token: token})
            if (this.ctcLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.ctcLoadFormState.orders[groupIndex].documents.splice(index, 1);
            }
        },
        handleEditCtcLoadDocumentClick(groupIndex, token) {
            const index = _.findIndex(this.ctcLoadFormState.orders[groupIndex].documents, {token: token})
            if (this.ctcLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.ctcLoadFormState.orders[groupIndex].document = {...this.ctcLoadFormState.orders[groupIndex].documents[index]};
            }
        },
        resetCtcLoadDocument(groupIndex) {
            this.ctcLoadFormState.orders[groupIndex].document = {...CTC_LOAD_DOCUMENT_STATE}
            this.ctcLoadFormState.orders[groupIndex].document.error = false;
        },
    },
}
