import {request} from '../../../util/Request'
import Error from "../../../util/Error";

const REEXPORT_LOAD_DOCUMENT_STATE = {
    token: null,
    id: null,
    document: null,
    title: null,
    error: false
};

const DEFAULT_REEXPORT_ORDER_STATE = {
    supplier_carrier_id: null,
    supplier_compound_id: null,
    from_location_id: null,
    to_location_id: null,
    client_id: null,
    price: null,
    currency: null,
    document: {...REEXPORT_LOAD_DOCUMENT_STATE},
    documents: [],
    ids: [],
    errors: null,
}

const DEFAULT_FORM_STATE = {
    type: 1,
    orders: [], // DEFAULT_REEXPORT_ORDER_STATE
}

export default {
    data() {
        return {
            reexportSelectedOrders: [],
            groupedReexportOrders: [],
            selectedReexportOrderLength: 0,
            reexportLoadFormVisible: false,
            reexportLoadFormState: {...DEFAULT_FORM_STATE},
            dropdowns: {
                carrierSuppliers: [],
            },
        }
    },
    methods: {
        handleReexportLoadAddClick() {
            const self = this
            let from_to_keys = [] // array of from location id and to Location ids
            _.map(self.reexportSelectedOrders, (item) => {
                from_to_keys.push(`${item.to_location.id} ${item.client.id} ${item.supplier_compound.id} ${item.brand.id}`)
            })

            _.map(_.uniqBy(from_to_keys), (item, index) => {
                const locations = item.split(' ')
                const items = _.filter(self.reexportSelectedOrders, (order) => (
                    order.to_location.id === locations[0] &&
                    order.client.id === locations[1] &&
                    order.supplier_compound.id === locations[2] &&
                    order.brand.id === locations[3]
                ))

                self.groupedReexportOrders.push({
                    from_location: items[0].to_location,
                    supplier_compound: items[0].supplier_compound,
                    client: items[0].client,
                    brand: items[0].brand,
                    items: items,
                    models: _.uniqBy(items, 'model.id').map(i => i.model.id)
                })

                self.reexportLoadFormState.orders.push({
                    ids: items.map((order) => order.id), // OrderIds
                    client_id: items[0].client.id,
                    supplier_compound_id: items[0].supplier_compound.id,
                    from_location_id: items[0].to_location.id,
                    supplier_carrier_id: null,
                    to_location_id: null,
                    price: 0,
                    currency: null,
                    errors: '',
                    document: {...REEXPORT_LOAD_DOCUMENT_STATE},
                    documents: [],
                })
            })

            this.reexportLoadFormVisible = true
        },
        async handleReexportLoadSubmit(urlToSend = 'reexport/loads/create') {
            if (this.validateRxLoad())
                return;

            try {
                const response = await request({
                    url: urlToSend,
                    method: 'post',
                    data: {...this.reexportLoadFormState},
                })

                this.loadCreateSuccess()
                this.handleReexportLoadFormOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.validationError()
                    return false
                }

                this.handleServerError(error)
            }
        },
        validateRxLoad() {
            let errors = false

            _.map(this.reexportLoadFormState.orders, (item, index) => {
                this.reexportLoadFormState.orders[index].errors = '';
                if(!item.supplier_carrier_id) {
                    errors = true
                    this.reexportLoadFormState.orders[index].errors = this.$t('msc.requiredFieldsMissing') + '<br>'
                }

                if (this.reexportLoadFormState.type == 1 && (!item.price || item.price <= 0 || !item.currency)) {
                    errors = true
                    this.reexportLoadFormState.orders[index].errors = this.$t('msc.requiredFieldsMissing') + '<br>'
                }
            })

            return errors;
        },
        handleReexportRowSelection(item, rowIndex, event) {
            let response = true;
            if (item.load_status === 8 && item.tr_status === 3 && item.tr_status_type === "reexport" && (item.client_tr_at && item.client_tr_accept_at) && item.has_call_of_reexport) {
                const index = _.findIndex(this.reexportSelectedOrders, {id: item.id});
                if (index >= 0) { // if already exist then delete
                    setTimeout(() => this.$refs.orderTable.unselectRow(rowIndex), 200)
                    delete this.reexportSelectedOrders[index]
                    this.selectedReexportOrderLength = this.selectedReexportOrderLength - 1
                } else { // else add new
                    this.reexportSelectedOrders.push(item)
                    setTimeout(() => this.$refs.orderTable.selectRow(rowIndex), 200)
                    this.selectedReexportOrderLength = this.selectedReexportOrderLength + 1
                }
            } else {
                response = false
            }

            this.reexportSelectedOrders = _.compact(this.reexportSelectedOrders)
            return response;
        },
        handleReexportLoadFormOperationClose(refreshList = true) {
            this.reexportLoadFormState = {...DEFAULT_FORM_STATE}
            this.reexportLoadFormState.orders.length = 0
            this.reexportLoadFormVisible = false
            this.groupedReexportOrders.length = 0
            this.reexportSelectedOrders.length = 0
            this.selectedReexportOrderLength = 0
            this.$refs.orderTable.clearSelected()
            this.formErrors = new Error({})

            if (refreshList) { // to handle call from refreshList (When page change)
                this.refreshList()
            }
        },
        handleAddUpdateReexportLoadDocumentClick(groupIndex) {
            this.reexportLoadFormState.orders[groupIndex].document.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.reexportLoadFormState.orders[groupIndex].document.document || this.reexportLoadFormState.orders[groupIndex].document.document.length <= 0 || !this.reexportLoadFormState.orders[groupIndex].document.document.id) {
                this.reexportLoadFormState.orders[groupIndex].document.error = true;
                errors.document = [this.$t('validation.required')];
            }

            if (!this.reexportLoadFormState.orders[groupIndex].document.title || _.trim(this.reexportLoadFormState.orders[groupIndex].document.title).length <= 2) {
                this.reexportLoadFormState.orders[groupIndex].document.error = true;
                errors.title = [this.$t('validation.required')];
            }

            _.map(this.reexportLoadFormState.orders[groupIndex].documents, (document) => {
                if (document.token !== this.reexportLoadFormState.orders[groupIndex].document.token) {
                    if (document.title === this.reexportLoadFormState.orders[groupIndex].document.title) {
                        errors.title = [this.$t('validation.duplicate')];
                        this.reexportLoadFormState.orders[groupIndex].document.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if (this.reexportLoadFormState.orders[groupIndex].document.error) return false;

            const entity = this.reexportLoadFormState.orders[groupIndex].document;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.reexportLoadFormState.orders[groupIndex].documents, {token: entity.token});

            if (this.reexportLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.reexportLoadFormState.orders[groupIndex].documents[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                this.reexportLoadFormState.orders[groupIndex].documents.push(entity)
            }

            this.reexportLoadFormState.orders[groupIndex].document = {
                ...REEXPORT_LOAD_DOCUMENT_STATE,
                token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            }
        },
        handleDeleteReexportLoadDocumentClick(groupIndex, token) {
            const index = _.findIndex(this.reexportLoadFormState.orders[groupIndex].documents, {token: token})
            if (this.reexportLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.reexportLoadFormState.orders[groupIndex].documents.splice(index, 1);
            }
        },
        handleEditReexportLoadDocumentClick(groupIndex, token) {
            const index = _.findIndex(this.reexportLoadFormState.orders[groupIndex].documents, {token: token})
            if (this.reexportLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.reexportLoadFormState.orders[groupIndex].document = {...this.reexportLoadFormState.orders[groupIndex].documents[index]};
            }
        },
        resetReexportLoadDocument(groupIndex) {
            this.reexportLoadFormState.orders[groupIndex].document = {...REEXPORT_LOAD_DOCUMENT_STATE}
            this.reexportLoadFormState.orders[groupIndex].document.error = false;
        },
        handleAfterLocationCreate(inputs, index) {
            const locations = [...this.dropdowns.locations, {id: inputs.id, label: inputs.label, ...inputs}];
            this.$set(this.dropdowns, 'locations', locations)
            this.reexportLoadFormState.orders[index].to_location_id = inputs.id;
        },
        handleAfterLocationUpdate(inputs, index) {
            const locations = [...this.dropdowns.locations, {id: inputs.id, label: inputs.label, ...inputs}];
            this.$set(this.dropdowns, 'locations', locations)
            this.reexportLoadFormState.orders[index].to_location_id = inputs.id;
        },
        handleAfterSupplierCreate(inputs, index) {
            const supplierCarriers = [...this.dropdowns.supplierCarriers, {id: inputs.id, label: inputs.name}];
            this.$set(this.dropdowns, 'supplierCarriers', supplierCarriers)
            this.reexportLoadFormState.orders[index].supplier_carrier_id = inputs.id;
        },
        handleAfterSupplierUpdate(inputs, index) {
            const supplierCarriers = [...this.dropdowns.supplierCarriers, {id: inputs.id, label: inputs.name}];
            this.$set(this.dropdowns, 'supplierCarriers', supplierCarriers)
            this.reexportLoadFormState.orders[index].supplier_carrier_id = inputs.id;
        },
    },
}
