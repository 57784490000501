<template>
    <div>
        <div v-if="show">
            <div class="card" v-show="operation !== 'detail'">
                <div class="card-header card-header-flex pb-2">
                    <div class="w-100 mt-2">
                        <div class="row">
                            <div class="col-sm-3 col-md-4 col-lg-4">
                                <h5 class="mt-3 ml-0 mr-3 mb-2">
                                    <strong>
                                        <template v-if="operation === null">
                                            {{$t('title.stock')}}
                                            <span v-if="pagination.total">({{pagination.total}})</span>
                                        </template>
                                        <template v-else>{{ $t(operationTitle) }}</template>
                                    </strong>
                                </h5>
                            </div>
                            <div class="col-sm-9 col-md-8 col-lg-8 text-right">
                                <div v-if="operation === null">
                                    <div class="mt-3">
                                        <!-- Using components -->
                                        <b-input-group class="mt-3">
                                            <b-form-input type="search" class="form-control form-control-sm"
                                                          :placeholder="$t('input.whatAreYouLookingFor')"
                                                          v-on:keyup.enter="handleSearch"
                                                          v-model="search"></b-form-input>
                                            <b-input-group-append>
                                                <b-button @click="handleCtcLoadAddClick" size="sm"
                                                          :title="$t('button.title.createInboundLoad')"
                                                          :disabled="selectedCtcOrderLength <= 0"
                                                          variant="wood"
                                                          v-if="$global.hasPermission('compoundtocompoundsstore')"
                                                          v-b-tooltip.hover>
                                                    <i class="fe fe-plus"></i> <strong>I </strong>
                                                    <b-badge variant="dark">{{selectedCtcOrderLength}}</b-badge>
                                                </b-button>
                                                <b-button @click="handleReexportLoadAddClick" variant="success"
                                                          size="sm"
                                                          :title="$t('button.title.createReexportLoad')"
                                                          :disabled="selectedReexportOrderLength <= 0"
                                                          v-if="$global.hasPermission('reexportloadsstore')"
                                                          v-b-tooltip.hover>
                                                    <i class="fe fe-plus"></i> <strong>R </strong>
                                                    <b-badge variant="dark">{{selectedReexportOrderLength}}</b-badge>
                                                </b-button>
                                                <b-button @click="handlePriority" variant="info" size="sm"
                                                          :title="$t('button.title.priority')"
                                                          :disabled="selectedOrderLength <= 0"
                                                          v-if="$global.hasPermission('ordersupdate')"
                                                          v-b-tooltip.hover>
                                                    <i class="fe fe-plus"></i> {{$t('button.priority')}}
                                                    <b-badge variant="dark">{{selectedOrderLength}}</b-badge>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                          variant="outline-info"
                                                          @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                          v-if="$global.hasPermission('ordersview')">
                                                    <i class="fa fa-filter"></i>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.exportExcel')"
                                                          variant="outline-info"
                                                          @click="handleExcelExportClick" v-b-tooltip.hover
                                                          v-if="$global.hasPermission('ordersview')">
                                                    <i class="fa fa-file-excel-o"></i>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.resetList')"
                                                          variant="outline-info"
                                                          @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                    <i class="fa fa-refresh"></i>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div v-else>
                                    <b-button variant="warning" size="sm" class="mt-3"
                                              @click="handleOperationClose()"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </div>
                            </div>
                        </div><!-- /.row -->
                    </div><!-- /.w-100 -->
                </div><!-- /.card-header -->
                <div class="card-body">
                    <div class="orders-table">
                        <b-table hover responsive
                                 :busy="listingLoading"
                                 :items="dataSource"
                                 :fields="columns"
                                 :sort-by.sync="sortField"
                                 ref="orderTable"
                                 @sort-changed="handleSortChange"
                                 @row-clicked="handleRowClick"
                                selectable>
                            <template v-slot:cell(supplier_compound_id)="{ detailsShowing, item, index, rowSelected}">
                                <a @click="toggleDetails(item)" class="d-inline in-center">
                                    <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                                </a>
                                <span class="indicator-added-inbound-load"
                                      v-if="item.load_status < 7 && item.added_in_load_at"
                                      :title="$t('title.inboundAdded')" v-b-tooltip.hover></span>
                                <span class="indicator-added-outbound-load"
                                      v-if="item.load_status >= 10 && item.load_status < 17"
                                      :title="$t('title.outboundAdded')" v-b-tooltip.hover></span>
                                {{item.supplier_compound ? item.supplier_compound.name : ''}}
                            </template>
                            <template v-slot:cell(gate_in_date)="record">
                                {{ (_.find(record.item.load_status_Histories, {status: 7})) ?
                                $global.utcDateToLocalDate(_.find(record.item.load_status_Histories, {status:
                                7}).created_at) : ''}}
                            </template>
                            <template v-slot:cell(order_countrys)="record">
                                {{ (record.item.order_country) }}
                            </template>
                            <template v-slot:cell(dealer_id)="record">
                                {{ (record.item.dealer ? record.item.dealer.name : '') }}
                            </template>
                            <template v-slot:cell(load_status)="{item}">
                                <div>
                                    <ol-status :status="item.load_status"></ol-status>
                                </div>
                                <tr-status v-if="item.tr_status > 0" :status="item.tr_status"
                                           :type="item.tr_status_type"></tr-status>
                                <template v-if="item.load_status == '9'"><br>
                                    <b-badge v-if="item._additional_services > 0 && item.damage > 0" variant="info">
                                        {{$t('title.serviceOrders')}} & {{$t('title.damaged')}}
                                    </b-badge>
                                    <b-badge v-if="item._additional_services > 0 && !item.damage > 0" variant="info">
                                        {{$t('title.serviceOrders')}}
                                    </b-badge>
                                    <b-badge v-if="!item._additional_services > 0 && item.damage > 0" variant="info">
                                        {{$t('title.damaged')}}
                                    </b-badge>
                                    <b-badge v-if="!item._additional_services > 0 && !item.damage > 0" variant="info">{{
                                        (_.find(item.load_status_Histories, {status: 9}).comments) ?
                                        _.find(item.load_status_Histories, {status: 9}).comments : $t('title.other')}}
                                    </b-badge>
                                </template>
                                <template v-if="item.client_affected"><br>
                                    <b-badge v-if="item.client_affected_priority === 1" variant="danger"
                                             :title="$t('title.clientAffected') + ' | ' + $t('title.high') + (item.client_affected_description ? ' | ' + item.client_affected_description : '')"
                                             v-b-tooltip.hover>{{$t('title.clientAffected')}}
                                    </b-badge>
                                    <b-badge v-if="item.client_affected_priority === 2" variant="warning"
                                             :title="$t('title.clientAffected') + ' | ' + $t('title.medium') + (item.client_affected_description ? ' | ' + item.client_affected_description : '')"
                                             v-b-tooltip.hover>{{$t('title.clientAffected')}}
                                    </b-badge>
                                    <b-badge v-if="item.client_affected_priority === 3" variant="secondary"
                                             :title="$t('title.clientAffected') + ' | ' + $t('title.low') + (item.client_affected_description ? ' | ' + item.client_affected_description : '')"
                                             v-b-tooltip.hover>{{$t('title.clientAffected')}}
                                    </b-badge>
                                </template>
                                <template v-if="item.load_status == 8 && _.isEmpty(item.client_tr_at)"><br>
                                    <b-badge v-if="item.client_tr_at && _.isEmpty(item.client_tr_accept_at)"
                                             variant="dark">{{$t('title.deliveryRequest')}}
                                    </b-badge>
                                    <b-badge v-if="!_.isEmpty(item.client_tr_accept_at)" variant="dark">
                                        {{$t('title.readyToBeLoaded')}}
                                    </b-badge>
                                </template>
                            </template>
                            <template v-slot:cell(brand_id)="record">
                                <a-avatar shape="square" :size="32" icon="car"
                                          :title="record.item.brand ? record.item.brand.title : ''"
                                          v-if="record.item.brand.logo && record.item.brand.logo.download_url"
                                          :src="record.item.brand.logo.system_url+'/thumbnail/32x32/'+record.item.brand.logo.name2"/>
                                <a-avatar shape="square" :size="32" icon="car"
                                          :title="record.item.brand ? record.item.brand.title : ''" v-else/>
                            </template>
                            <template v-slot:cell(model_id)="record">
                                {{ (record.item.model ? record.item.model.title : '') }}
                            </template>
                            <template v-slot:cell(updated_at)="record">
                                {{ $global.utcDateToLocalDate(record.item.updated_at) }}
                            </template>
                            <template v-slot:cell(logistic_type)="record">
                                <span class="text-capitalize">
                                    <span
                                        v-if="record.item.tr_status_type == 'outbound'">{{$t('title.outbound')}}</span>
                                    <span
                                        v-if="record.item.tr_status_type == 'reexport'">{{$t('title.reexport')}}</span>
                                    <span
                                        v-if="record.item.tr_status_type == 'dd'">D2D</span>
                                    <span v-if="record.item.tr_status_type == 'cc'">CC</span>
                                </span>
                            </template>
                            <template v-slot:cell(action)="{item}">
                                <a @click="handleStatusUpdateClick(item)"
                                   :title="$t('button.title.statusUpdate')"
                                   v-if="item.tr_status <= 2 && (item.load_status == 8 || item.load_status == 9) && ($global.hasRole('efl') || $global.hasRole('superadmin') || $global.hasRole('efluser'))"
                                   class="ml-1"
                                   v-b-tooltip.hover>
                                    <i class="fe fe-triangle"></i>
                                </a>
                                <a @click="setOperation('detail', item.id)"
                                   :title="$t('button.title.detailItem')" v-if="$global.hasPermission('ordersview')"
                                   class="ml-1"
                                   v-b-tooltip.hover>
                                    <i class="icmn-info"></i>
                                </a>
                            </template>
                            <template v-slot:row-details="{ item }">
                                <b-card>
                                    <b-list-group flush>
                                        <b-list-group-item><strong>{{$t('column.importerNumber')}}</strong>:
                                            {{item.invoice_number_importer}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('column.invoiceDateImporter')}}</strong>:
                                            {{item.invoice_date_importer}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.inboundSupplierCarrier')}}</strong>:
                                            {{item.supplier_carrier ? item.supplier_carrier.name: ''}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.inboundSupplierCompound')}}</strong>:
                                            {{item.supplier_compound ? item.supplier_compound.name: ''}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.outboundSupplierCarrier')}}</strong>:
                                            {{item.outbound_supplier_carrier ? item.outbound_supplier_carrier.name :
                                            ''}}
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <strong>{{$t('title.destinationLocation')}}</strong>:
                                            <template v-if="item.dealer_location">
                                                <print-ad :item="item.dealer_location"></print-ad>
                                            </template>
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.transportRequestAt')}}</strong>:
                                            {{item.client_tr_at ? $global.utcDateToLocalDate(item.client_tr_at) : ''}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.transportRequestAcceptedAt')}}</strong>:
                                            {{item.client_tr_accept_at ?
                                            $global.utcDateToLocalDate(item.client_tr_accept_at) : ''}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('column.comment')}}</strong>: {{item.comments}}
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </template>
                        </b-table><!-- /.b-table -->

                        <div class="clearfix">
                            <div class="float-left">
                                <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                               size="sm"></b-form-select>
                            </div>
                            <div class="float-right">
                                <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                                ></b-pagination>
                            </div><!-- /.pull-right -->
                        </div><!-- /.clearfix -->
                    </div><!-- /.orders-table-->
                    <div class="orders-operation"></div><!--/.orders-operation-->
                    <div class="filter-container">
                        <a-drawer
                            placement="left"
                            :width="'360px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="filters.visible = !filters.visible"
                            :visible="!operation && filters.visible"
                            :zIndex="10"
                            :title="$t('title.advanceFilters')"
                        >
                            <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                                <b-row>
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.brands')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.brands"
                                                placeholder=""
                                                v-model="filters.brands"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.models')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.models"
                                                placeholder=""
                                                v-model="filters.models"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.code')">
                                            <treeselect
                                                :multiple="true"
                                                :options=" _.uniqBy(_.filter(dropdowns.configCode, ({ model_id }) => _.every([_.includes(filters.models, model_id)])),'id')"
                                                placeholder=""
                                                v-model="filters.configCode"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.clients')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.clients"
                                                placeholder=""
                                                v-model="filters.clients"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.dealers')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.dealers"
                                                placeholder=""
                                                v-model="filters.dealers"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.supplierCompound')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.supplierCompounds"
                                                placeholder=""
                                                v-model="filters.suppliers"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.status')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.status"
                                                placeholder=""
                                                v-model="filters.status"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('title.additionalInformation')">
                                            <treeselect
                                                :multiple="false"
                                                :options="dropdowns.statusAdditionalInfo"
                                                placeholder=""
                                                v-model="filters.status_additional_info"/>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col>
                                        <b-form-group
                                            :label="$t('input.orderTransportStatus')">
                                            <treeselect
                                                :multiple="true"
                                                :options="[{id:1,label:'Pending'},{id:2,label:'Rejected'},{id:3,label:'Approved'}]"
                                                placeholder=""
                                                v-model="filters.orderTransportStatus"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.transportRequestType')">
                                            <treeselect
                                                :multiple="true"
                                                :options="[{id:'outbound',label:'outbound'},{id:'cc',label:'cc'},{id:'reexport',label:'reexport'}]"
                                                placeholder=""
                                                v-model="filters.transportRequestType"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.markets')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.countries"
                                                placeholder=""
                                                v-model="filters.order_countries"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.fromAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.toAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                </b-row>
                                <div class="drawer-footer">
                                    <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                              class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                        {{$t('button.close')}}
                                    </b-button>
                                    <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                              :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                        {{$t('button.reset')}}
                                    </b-button>
                                    <b-button size='sm' variant="primary" button="submit" type="filled"
                                              :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                        {{$t('button.apply')}}
                                    </b-button>
                                </div><!-- /.drawer-footer -->
                            </form>
                        </a-drawer>
                    </div><!-- /.filter-container -->
                </div><!-- /.card-body-->
            </div><!-- /.card -->
            <div v-if="operation === 'detail'">
                <detail :handle-close-operation="handleOperationClose"></detail>
            </div>
        </div>

        <b-modal id="excel-export-modal" v-model="exportModal.visibility" hide-footer>
            <template #modal-title>
                {{$t('title.exportExcel')}}
            </template>
            <div>
                <form @submit.prevent="handleExcelExportSubmitClick">
                    <div class="mb-5">
                        <b-form-group>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportSelectAllClick">{{$t('input.checkAll')}}</a>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportUnSelectAllClick">{{$t('input.unCheckAll')}}</a>
                        </b-form-group>
                        <hr>
                        <table class="table table-sm">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{$t('title.columns')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(column, index) in exportModal.columns">
                                <td>{{index + 1}}</td>
                                <td>
                                    <b-form-checkbox v-model="column.show"
                                                     name="check-button">{{column.label}}
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="drawer-footer">
                        <json-excel
                            class="btn btn-primary btn-sm"
                            :fields="exportModal.headers"
                            worksheet="Orders"
                            :fetch="handleExcelExportSubmitClick"
                            name="orders.xls">
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.export')}}
                        </json-excel>
                        <b-button variant="warning" class="ml-3"
                                  size="sm" @click="handleExcelExportCancelClick()"
                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                        </b-button>
                    </div>
                </form>
            </div>
        </b-modal><!--/#excel-export-modal-->

        <!-- # Start Priority -->
        <div class="priority-operation">
            <a-drawer
                placement="right"
                :width="'450px'"
                :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                :closable="false"
                @close="handlePriorityFormOperationClose(false)"
                :visible="priorityFormVisible"
                :zIndex="10"
                :title="$t('title.addPriority')"
            >
                <form @submit.prevent="handlePrioritySubmit(false)" autocomplete="off">
                    <b-row>
                        <b-col cols="12" v-show="global.pendingRequests > 0">
                            <a-skeleton active :paragraph="{ rows: 5 }"/>
                        </b-col>
                        <b-col cols="12" v-show="global.pendingRequests <= 0">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.priority')+' *'"
                                        label-for="client_affected_priority"
                                        :invalid-feedback="formErrors.first('client_affected_priority')">
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.priorities"
                                            placeholder=""
                                            v-model="priorityFormState.client_affected_priority"
                                            :class="[{'invalid is-invalid': (formErrors.has('client_affected_priority'))}]"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.comment')"
                                        label-for="client_affected_description"
                                        :invalid-feedback="formErrors.first('client_affected_description')"
                                    >
                                        <b-form-textarea
                                            id="client_affected_description"
                                            v-model="priorityFormState.client_affected_description"
                                            :state="((formErrors.has('client_affected_description') ? false : null))"
                                            @focus="$event.target.select()"
                                        ></b-form-textarea>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row><!--/b-row-->
                        </b-col><!--/b-col-->
                        <div class="drawer-footer">
                            <b-row>
                                <b-col sm="6" class="text-left">
                                    <b-form-group
                                        :invalid-feedback="formErrors.first('client_affected')"
                                    >
                                        <b-form-checkbox v-model="priorityFormState.client_affected"
                                                         name="client_affected">
                                            {{$t('input.clientAffected')}}
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="6">
                                    <b-button
                                        size="sm"
                                        type="submit"
                                        variant="primary"
                                        :disabled="global.pendingRequests > 0"
                                        v-b-tooltip.hover :title="$t('button.title.save')"
                                    >
                                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                                     size="12px"
                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                        <i class="fa fa-save mr-1"></i>
                                        {{$t('button.save')}}
                                    </b-button>
                                    <b-button variant="warning" class="ml-3"
                                              size="sm" @click="handlePriorityFormOperationClose(false)"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-row><!--/b-row-->
                </form><!--/form-->
            </a-drawer>
        </div><!--/.priority-operation-->

        <!-- # Start Status Update -->
        <div class="status-update-operation">
            <a-drawer
                placement="right"
                :width="'450px'"
                :wrapStyle="{overflow: 'auto', paddingBottom: '108px'}"
                :closable="false"
                @close="handleStatusFormOperationClose(false)"
                :visible="statusFormVisible"
                :zIndex="10"
                :title="$t('title.updateStockStatus')"
            >
                <form @submit.prevent="handleStatusUpdateSubmit" autocomplete="off">
                    <b-row>
                        <b-col cols="12" v-show="global.pendingRequests > 0">
                            <a-skeleton active :paragraph="{ rows: 5 }"/>
                        </b-col>
                        <b-col cols="12" v-show="global.pendingRequests <= 0">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.status')+' *'"
                                        label-for="status"
                                        :invalid-feedback="formErrors.first('status')">
                                        <treeselect
                                            :multiple="false"
                                            :options="[{id: 8, label: $t('title.stocked')}, {id: 9, label: $t('title.hold')}]"
                                            placeholder=""
                                            v-model="statusFormState.status"
                                            :class="[{'invalid is-invalid': (formErrors.has('client_affected_priority'))}]"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.comment')"
                                        label-for="note"
                                        :invalid-feedback="formErrors.first('note')"
                                    >
                                        <b-form-textarea
                                            id="note"
                                            v-model="statusFormState.note"
                                            :state="((formErrors.has('note') ? false : null))"
                                            @focus="$event.target.select()"
                                        ></b-form-textarea>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row><!--/b-row-->
                        </b-col><!--/b-col-->
                        <div class="drawer-footer">
                            <b-row>
                                <b-col sm="12">
                                    <b-button
                                        size="sm"
                                        type="submit"
                                        variant="primary"
                                        :disabled="global.pendingRequests > 0"
                                        v-b-tooltip.hover :title="$t('button.title.save')"
                                    >
                                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                                     size="12px"
                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                        <i class="fa fa-save mr-1"></i>
                                        {{$t('button.save')}}
                                    </b-button>
                                    <b-button variant="warning" class="ml-3"
                                              size="sm" @click="handleStatusFormOperationClose(false)"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-row><!--/b-row-->
                </form><!--/form-->
            </a-drawer>
        </div><!--/.status-update-operation-->

        <!-- # Start Ctc Load -->
        <a-drawer
            placement="right"
            :width="'86%'"
            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
            :closable="false"
            @close="handleCtcLoadFormOperationClose"
            :visible="ctcLoadFormVisible"
            :zIndex="10"
            :title="$t('title.createCtcLoad')"
        >
            <form @submit.prevent="handleCtcLoadSubmit" autocomplete="off">
                <b-row v-if="groupedCtcOrders.length > 0">
                    <b-col sm="12" v-for="(group, index) in groupedCtcOrders" :key="index">
                        <b-row>
                            <b-col sm="12" lg="2" md="2" class=" mb-sm-3">
                                <h4 class="d-sm-inline">
                                    <span class="bg-gray-6 ml-2 rounded text-white p-1">
                                        {{$t('title.load')}}#<strong>{{index + 1}}</strong>
                                    </span>
                                </h4>
                                <h4 class="mt-4 ml-2 d-sm-inline">
                                    <span class="bg-light rounded p-1">
                                        <small>{{$t('title.orders')}}-{{group.items.length}}</small>
                                    </span>
                                </h4>
                            </b-col>
                            <b-col sm="12" lg="3" md="3" class="ml-sm-1 mt-sm-2">
                                <dl>
                                    <dt>{{$t('title.fromLocation')}}:</dt>
                                    <dd>
                                        <print-ad :item="group.from_location"></print-ad>
                                    </dd><!--/FromLocation-->

                                    <dt>{{$t('title.toLocation')}}:</dt>
                                    <dd>
                                        <print-ad :item="group.to_location"></print-ad>
                                    </dd><!--/ToLocation-->

                                    <dt>VIN:</dt>
                                    <dd>
                                        <div v-for="(order, index) in group.items">
                                            {{order.vin_number}}
                                        </div>
                                    </dd>
                                </dl>
                            </b-col>
                            <b-col sm="12" lg="3" md="3" class="ml-sm-1 mt-sm-2">
                                <dl>
                                    <dt>{{$t('title.brand')}}:</dt>
                                    <dd>
                                        <div v-for="(order, index) in _.uniqBy(group.items, 'brand.title')">
                                            {{order.brand.title}}
                                        </div>
                                    </dd><!--/model-->

                                    <dt>{{$t('title.model')}}:</dt>
                                    <dd>
                                        <div v-for="(order, index) in _.uniqBy(group.items, 'model.title')">
                                            {{order.model.title}}-{{order.model.version_code}}
                                        </div>
                                    </dd><!--/model-->

                                    <dt>{{$t('title.supplierCompound')}}:</dt>
                                    <dd>
                                        {{group.from_supplier_compound.name}}
                                    </dd><!--/ToLocation-->
                                </dl>
                            </b-col>
                            <b-col sm="12" lg="3" md="3">
                                <div>
                                    <small v-if="ctcLoadFormState.orders[index].errors" class="mb-2 d-block">
                                        <span class="text-danger" v-html="ctcLoadFormState.orders[index].errors"></span>
                                    </small>
                                </div>
                                <div class="form-group mb-2">
                                    <label class="d-block">{{$t('input.supplierCarrier')}} *</label>
                                    <treeselect
                                        :multiple="false"
                                        :options="
                                            _.filter(dropdowns.carrierSuppliers, (supplier) =>
                                                    (
                                                        _.filter(supplier.prices, ({brands, route}) => (
                                                            route.from_location.id === group.from_location.id &&
                                                            route.to_location.id === group.to_location.id
                                                        )
                                                    ).length > 0
                                                )
                                            )
                                        "
                                        placeholder=""
                                        v-model="ctcLoadFormState.orders[index].supplier_carrier_id"
                                        :class="[{'invalid is-invalid': (formErrors.has('supplier_carrier_id') || formErrors.has('price_id') || formErrors.has('route_id'))}]"
                                        @input="(value, instance) => handleSupplierChange(value, group, index)"
                                    >
                                        <template slot="value-label" slot-scope="{ node }">
                                            <div :title="node.label">
                                                {{ node.label }}
                                            </div>
                                        </template>
                                    </treeselect>
                                    <div class="invalid-feedback">{{formErrors.first('supplier_carrier_id')}}</div>
                                    <div class="invalid-feedback">{{formErrors.first('price_id')}}</div>
                                    <div class="invalid-feedback">{{formErrors.first('route_id')}}</div>
                                </div><!-- /.form-group -->
                                <div class="form-group">
                                    <label class="d-block">{{$t('input.supplierCompound')}} *</label>
                                    <div v-if="group.supplier_compound">
                                        {{group.supplier_compound.name}}
                                    </div>
                                    <div class="invalid-feedback">{{formErrors.first('supplier_compound_id')}}</div>
                                </div><!-- /.form-group -->
                                <div class="form-group mb-0">
                                    <b-form-group label="Loading Type" v-slot="{ ariaDescribedby }">
                                        <b-form-radio v-model="ctcLoadFormState.orders[index].truck_load_type" :aria-describedby="ariaDescribedby" name="some-radios" value="ftl">FTL</b-form-radio>
                                        <b-form-radio v-model="ctcLoadFormState.orders[index].truck_load_type" :aria-describedby="ariaDescribedby" name="some-radios" value="spl">SPL</b-form-radio>
                                    </b-form-group>
                                </div>
                                <br>
                                <small v-if="ctcLoadFormState.orders[index].loading_factors > 0">
                                    {{$t('title.loadingFactors')}}: {{ctcLoadFormState.orders[index].loading_factors}}
                                    <br>
                                </small>
                                <small v-if="ctcLoadFormState.orders[index].loading_factors > 0">
                                    {{ctcLoadFormState.orders[index].loading_factor_note}}
                                    <br>
                                </small>
                                <small class="text-danger" v-if="!ctcLoadFormState.orders[index].price_id">
                                    {{ $t('msc.unableToDecidePrice') }}
                                </small>
                            </b-col>
                            <b-col lg="12" md="12" sm="12" class="mt-3">
                                <b-form-group
                                    :label="$t('input.comment')"
                                    label-for="comments"
                                    :invalid-feedback="formErrors.first('comments')"
                                >
                                    <b-form-textarea
                                        id="comments"
                                        v-model="ctcLoadFormState.orders[index].comments"
                                        :state="((formErrors.has('comments') ? false : null))"
                                    ></b-form-textarea>
                                </b-form-group>
                            </b-col>
                            <b-col lg="12" md="12" sm="12" class="mt-3 mb-5">
                                <b-card class="mb-0">
                                    <b-card-header v-b-toggle="'ctc-load-documents-'+index" class="p-0">
                                        <h4 class="mb-0">
                                            <span class="badge badge-primary">
                                                {{$t('title.attachments')}}
                                            </span>
                                            <small v-show="ctcLoadFormState.orders[index].documents.length > 0">
                                                - {{ctcLoadFormState.orders[index].documents.length}} Item/s
                                            </small>
                                        </h4>
                                    </b-card-header><!-- /.p-0-->
                                    <b-collapse :id="`ctc-load-documents-${index}`">
                                        <div class="bg-light p-3">
                                            <b-row>
                                                <b-col lg="4" md="4" sm="12">
                                                    <div class="form-group">
                                                        <b-form-group
                                                            :label="$t('input.title')+' *'"
                                                            label-for="title"
                                                            :invalid-feedback="formErrors.first('title')"
                                                        >
                                                            <b-form-input
                                                                id="title"
                                                                v-model="ctcLoadFormState.orders[index].document.title"
                                                                type="text"
                                                                :state="((formErrors.has('title') ? false : null))"
                                                                @focus="$event.target.select()"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                        <div class="invalid-feedback">{{formErrors.first('title')}}
                                                        </div>
                                                    </div><!-- /.form-group -->
                                                </b-col><!--/b-col-->
                                                <b-col lg="4" md="4" sm="12">
                                                    <b-form-group class="mt-4 pt-2">
                                                        <upload
                                                            v-model="ctcLoadFormState.orders[index].document.document"
                                                            :disabled="ctcLoadFormState.orders[index].document.document"
                                                            :title="(ctcLoadFormState.orders[index].document.document ? $t('msc.uploadedFile') : $t('msc.uploadFile'))"
                                                            css-class="mt-0 btn-sm"></upload>
                                                        <b-button :title="$t('msc.removeUpload')"
                                                                  type="button"
                                                                  variant="outline-primary"
                                                                  v-b-tooltip.hover
                                                                  class="ml-2 ml-2 btn-sm"
                                                                  @click="() => {ctcLoadFormState.orders[index].document.document = null;}"
                                                                  :disabled="!ctcLoadFormState.orders[index].document.document"
                                                                  v-if="ctcLoadFormState.orders[index].document.document">
                                                            <i class="fa fa-close"></i>
                                                        </b-button>
                                                        <b-button :title="$t('button.download')"
                                                                  v-b-tooltip.hover
                                                                  type="button"
                                                                  variant="outline-primary"
                                                                  class="ml-2 ml-2 btn-sm"
                                                                  v-if="ctcLoadFormState.orders[index].document.document && ctcLoadFormState.orders[index].document.document.download_url"
                                                                  :disabled="!(ctcLoadFormState.orders[index].document.document && ctcLoadFormState.orders[index].document.document.download_url)"
                                                                  :href="(ctcLoadFormState.orders[index].document.document ? ctcLoadFormState.orders[index].document.document.download_url : '')"
                                                                  target="_blank">
                                                            <i class="fa fa-cloud-download"></i>
                                                        </b-button>
                                                    </b-form-group>
                                                    <div class="invalid-feedback d-block">
                                                        {{formErrors.first('document')}}
                                                    </div>
                                                </b-col><!--/b-col-->
                                                <b-col lg="2" md="2" sm="12">
                                                    <div class="form-group">
                                                        <label class="d-block">
                                                            <pre> </pre>
                                                        </label>
                                                        <b-button variant="info" type="button" size="sm"
                                                                  class="btn-block"
                                                                  @click="handleAddUpdateCtcLoadDocumentClick(index)">
                                                            <i class="fa fa-plus"></i>
                                                        </b-button><!--/b-button-->
                                                    </div><!-- /.form-group -->
                                                </b-col><!--/b-col-->
                                                <b-col lg="2" md="2" sm="12">
                                                    <div class="form-group">
                                                        <label class="d-block">
                                                            <pre> </pre>
                                                        </label>
                                                        <b-button variant="warning" type="button" size="sm"
                                                                  class="btn-block"
                                                                  @click="resetCtcLoadDocument(index)">
                                                            {{$t('button.reset')}}
                                                        </b-button><!--/b-button-->
                                                    </div><!-- /.form-group -->
                                                </b-col><!--/b-col-->
                                            </b-row><!--/b-row-->
                                            <b-row>
                                                <b-col cols="12">
                                                    <table class="table table-bordered bg-white">
                                                        <thead>
                                                        <tr>
                                                            <th width="50">#</th>
                                                            <th width="180">{{$t('column.title')}}</th>
                                                            <th width="60">{{$t('column.action')}}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr v-for="(ct, I) in ctcLoadFormState.orders[index].documents"
                                                            :class="[{'table-primary': ct.token === ctcLoadFormState.orders[index].document.token}]">
                                                            <td>{{I + 1}}</td>
                                                            <td>
                                                                {{ct.title}}
                                                            </td>
                                                            <td>
                                                                <a @click="handleEditCtcLoadDocumentClick(index, ct.token)"
                                                                   :title="$t('button.title.editItem')"
                                                                   v-b-tooltip.hover>
                                                                    <i class="fe fe-edit"></i>
                                                                </a>
                                                                <a :title="$t('button.download')" class=" ml-1"
                                                                   :href="ct.document.download_url"
                                                                   target="_blank" v-b-tooltip.hover>
                                                                    <i class="fa fa-cloud-download"></i>
                                                                </a>
                                                                <a-popconfirm title="Are you sure？"
                                                                              @confirm="handleDeleteCtcLoadDocumentClick(index, ct.token)">
                                                                    <i slot="icon" class="fe fe-trash"></i>
                                                                    <a class=" ml-1"
                                                                       :title="$t('button.title.deleteItem')"
                                                                       v-b-tooltip.hover>
                                                                        <i class="fe fe-trash"></i>
                                                                    </a>
                                                                </a-popconfirm>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                        <tfoot
                                                            v-show="ctcLoadFormState.orders[index].documents.length <= 0">
                                                        <tr>
                                                            <th colspan="3">{{$t('title.noDataAvailable')}}</th>
                                                        </tr>
                                                        </tfoot>
                                                    </table><!-- /.table table-bordered -->
                                                </b-col><!--/b-col-->
                                            </b-row><!--/b-row-->
                                        </div><!-- /.bg-light -->
                                    </b-collapse><!-- /#ctc-load-documents-->
                                </b-card><!-- /b-card -->
                            </b-col><!--/b-col-->
                        </b-row><!--/.row-->
                        <hr/>
                    </b-col><!--/.col-->
                </b-row><!--/.row-->

                <div class="drawer-footer">
                    <a-popconfirm title="Are you sure？" @confirm="handleCtcLoadSubmit()">
                        <a class="btn btn-sm btn-primary"
                           :disabled="global.pendingRequests > 0">
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.save')}}
                        </a>
                    </a-popconfirm>

                    <b-button variant="warning" class="ml-3"
                              size="sm" @click="handleCtcLoadFormOperationClose()"
                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                    </b-button>
                </div><!--/.drawer-footer-->
            </form><!--/form-->
        </a-drawer><!--/.drawer-->

        <!-- # Start ReExport Load -->
        <a-drawer
            placement="right"
            :width="'86%'"
            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
            :closable="false"
            @close="handleReexportLoadFormOperationClose"
            :visible="reexportLoadFormVisible"
            :zIndex="10"
        >

            <template slot="title">
                <div class="clearfix">
                    <div class="pull-left">{{$t('title.createReexportLoad')}}</div>
                    <div class="pull-right">
                        <b-form-checkbox
                            v-model="reexportLoadFormState.type"
                            name="checkbox-1"
                            value="2"
                            unchecked-value="1"
                        >
                            {{$t('title.pickupAnnouncement')}}
                        </b-form-checkbox>
                    </div>
                </div>
            </template>
            <form @submit.prevent="() => handleReexportLoadSubmit('reexport/loads/create')" autocomplete="off">
                <b-row v-if="groupedReexportOrders.length > 0">
                    <b-col sm="12" v-for="(group, index) in groupedReexportOrders" :key="index">
                        <b-row>
                            <b-col sm="12" lg="2" md="2" class=" mb-sm-3">
                                <h4 class="d-sm-inline">
                                    <span class="bg-gray-6 ml-2 rounded text-white p-1">
                                        {{$t('title.load')}}#<strong>{{index + 1}}</strong>
                                    </span>
                                </h4>
                                <h4 class="mt-4 ml-2 d-sm-inline">
                                    <span class="bg-light rounded p-1">
                                        <small>{{$t('title.orders')}}-{{group.items.length}}</small>
                                    </span>
                                </h4>
                            </b-col>
                            <b-col sm="12" lg="3" md="3" class="ml-sm-1 mt-sm-2">
                                <dl>
                                    <dt>{{$t('title.fromLocation')}}:</dt>
                                    <dd>
                                        <print-ad :item="group.from_location"></print-ad>
                                    </dd><!--/fromlocation-->
                                    <dt>{{$t('title.supplierCompound')}}:</dt>
                                    <dd>{{group.supplier_compound.name}}</dd><!--/supplier_compound-->

                                    <dt>VIN:</dt>
                                    <dd>
                                        <div v-for="(order, index) in group.items">
                                            {{order.vin_number}}
                                        </div>
                                    </dd>
                                </dl>
                            </b-col>
                            <b-col sm="12" lg="2" md="2" class="ml-sm-1 mt-sm-2">
                                <dl>
                                    <dt>{{$t('title.brand')}}:</dt>
                                    <dd>{{group.brand.title}}</dd><!--/brand-->

                                    <dt>{{$t('title.model')}}:</dt>
                                    <dd>
                                        <div v-for="(order, index) in _.uniqBy(group.items, 'model.title')">
                                            {{order.model.title}}-{{order.model.version_code}}
                                        </div>
                                    </dd><!--/model-->

                                    <dt>{{$t('title.client')}}:</dt>
                                    <dd>{{group.client.company_name}}</dd><!--/model-->
                                </dl>
                            </b-col>
                            <b-col sm="12" lg="4" md="4">
                                <div>
                                    <small v-if="reexportLoadFormState.orders[index].errors" class="mb-2 d-block">
                                        <span class="text-danger"
                                              v-html="reexportLoadFormState.orders[index].errors"></span>
                                    </small>
                                </div>
                                <div class="form-group mb-2">
                                    <div class="clearfix">
                                        <span class="pull-left">
                                            <label class="d-block">{{$t('input.supplierCarrier')}} *</label>
                                        </span><!-- /.pull-left -->
                                        <span class="pull-right">
                                            <quick-supplier-form
                                                allow-update="1"
                                                allow-create="1"
                                                :id="reexportLoadFormState.orders[index].supplier_carrier_id"
                                                :after-create="(inputs) => handleAfterSupplierCreate(inputs, index)"
                                                :after-update="(inputs) => handleAfterSupplierUpdate(inputs, index)">
                                            </quick-supplier-form>
                                        </span><!-- /.pull-right -->
                                    </div><!-- /.clearfix -->
                                    <treeselect
                                        :multiple="false"
                                        :options="dropdowns.supplierCarriers"
                                        placeholder=""
                                        v-model="reexportLoadFormState.orders[index].supplier_carrier_id"
                                        :class="[{'invalid is-invalid': (formErrors.has('supplier_carrier_id'))}]"
                                    />
                                    <div class="invalid-feedback">{{formErrors.first('supplier_carrier_id')}}</div>
                                </div><!-- /.form-group -->

                                <div class="form-group mb-2" v-show="reexportLoadFormState.type == 1">
                                    <div class="clearfix">
                                        <span class="pull-left">
                                            <label class="d-block">{{$t('input.toLocation')}} *</label>
                                        </span><!-- /.pull-left -->
                                        <span class="pull-right">
                                            <quick-location-form
                                                allow-update="1"
                                                allow-create="1"
                                                :id="reexportLoadFormState.orders[index].to_location_id"
                                                :after-create="(inputs) => handleAfterLocationCreate(inputs, index)"
                                                :after-update="(inputs) => handleAfterLocationUpdate(inputs, index)">
                                            </quick-location-form>
                                        </span><!-- /.pull-right -->
                                    </div><!-- /.clearfix -->
                                    <treeselect
                                        :multiple="false"
                                        :options="dropdowns.locations"
                                        placeholder=""
                                        v-model="reexportLoadFormState.orders[index].to_location_id"
                                        :class="[{'invalid is-invalid': (formErrors.has('to_location_id'))}]"
                                    />
                                    <div class="invalid-feedback">{{formErrors.first('to_location_id')}}</div>
                                </div><!-- /.form-group -->
                                <div class="row mb-2">
                                    <div class="col-sm-7" v-show="reexportLoadFormState.type == 1">
                                        <div class="form-group">
                                            <b-form-group
                                                :label="$t('input.price')+' *'"
                                                label-for="price"
                                                :invalid-feedback="formErrors.first('price')"
                                            >
                                                <b-form-input
                                                    id="price"
                                                    v-model="reexportLoadFormState.orders[index].price"
                                                    type="text"
                                                    :state="((formErrors.has('price') ? false : null))"
                                                ></b-form-input>
                                            </b-form-group>
                                        </div><!-- /.form-group -->
                                    </div>
                                    <div class="col-sm-5" v-show="reexportLoadFormState.type == 1">
                                        <div class="form-group">
                                            <b-form-group
                                                :label="$t('input.currency')+' *'"
                                                label-for="currency"
                                                :invalid-feedback="formErrors.first('currency')"
                                            >
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.currencies"
                                                    placeholder=""
                                                    v-model="reexportLoadFormState.orders[index].currency"
                                                    :class="[{'invalid is-invalid': (formErrors.has('currency'))}]"
                                                />
                                            </b-form-group>
                                        </div><!-- /.form-group -->
                                    </div>
                                </div>
                            </b-col>
                            <b-col lg="12" md="12" sm="12" class="mt-3 mb-5">
                                <b-card class="mb-0">
                                    <b-card-header v-b-toggle="'reexport-load-documents-'+index" class="p-0">
                                        <h4 class="mb-0">
                                            <span class="badge badge-primary">
                                                {{$t('title.attachments')}}
                                            </span>
                                            <small v-show="reexportLoadFormState.orders[index].documents.length > 0">
                                                - {{reexportLoadFormState.orders[index].documents.length}} Item/s
                                            </small>
                                        </h4>
                                    </b-card-header><!-- /.p-0-->
                                    <b-collapse :id="`reexport-load-documents-${index}`">
                                        <div class="bg-light p-3">
                                            <b-row>
                                                <b-col lg="4" md="4" sm="12">
                                                    <div class="form-group">
                                                        <b-form-group
                                                            :label="$t('input.title')+' *'"
                                                            label-for="title"
                                                            :invalid-feedback="formErrors.first('title')"
                                                        >
                                                            <b-form-input
                                                                id="title"
                                                                v-model="reexportLoadFormState.orders[index].document.title"
                                                                type="text"
                                                                :state="((formErrors.has('title') ? false : null))"
                                                                @focus="$event.target.select()"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                        <div class="invalid-feedback">{{formErrors.first('title')}}
                                                        </div>
                                                    </div><!-- /.form-group -->
                                                </b-col><!--/b-col-->
                                                <b-col lg="4" md="4" sm="12">
                                                    <b-form-group class="mt-4 pt-2">
                                                        <upload
                                                            v-model="reexportLoadFormState.orders[index].document.document"
                                                            :disabled="reexportLoadFormState.orders[index].document.document"
                                                            :title="(reexportLoadFormState.orders[index].document.document ? $t('msc.uploadedFile') : $t('msc.uploadFile'))"
                                                            css-class="mt-0 btn-sm"></upload>
                                                        <b-button :title="$t('msc.removeUpload')"
                                                                  type="button"
                                                                  variant="outline-primary"
                                                                  v-b-tooltip.hover
                                                                  class="ml-2 ml-2 btn-sm"
                                                                  @click="() => {reexportLoadFormState.orders[index].document.document = null;}"
                                                                  :disabled="!reexportLoadFormState.orders[index].document.document"
                                                                  v-if="reexportLoadFormState.orders[index].document.document">
                                                            <i class="fa fa-close"></i>
                                                        </b-button>
                                                        <b-button :title="$t('button.download')"
                                                                  v-b-tooltip.hover
                                                                  type="button"
                                                                  variant="outline-primary"
                                                                  class="ml-2 ml-2 btn-sm"
                                                                  v-if="reexportLoadFormState.orders[index].document.document && reexportLoadFormState.orders[index].document.document.download_url"
                                                                  :disabled="!(reexportLoadFormState.orders[index].document.document && reexportLoadFormState.orders[index].document.document.download_url)"
                                                                  :href="(reexportLoadFormState.orders[index].document.document ? reexportLoadFormState.orders[index].document.document.download_url : '')"
                                                                  target="_blank">
                                                            <i class="fa fa-cloud-download"></i>
                                                        </b-button>
                                                    </b-form-group>
                                                    <div class="invalid-feedback d-block">
                                                        {{formErrors.first('document')}}
                                                    </div>
                                                </b-col><!--/b-col-->
                                                <b-col lg="2" md="2" sm="12">
                                                    <div class="form-group">
                                                        <label class="d-block">
                                                            <pre> </pre>
                                                        </label>
                                                        <b-button variant="info" type="button" size="sm"
                                                                  class="btn-block"
                                                                  @click="handleAddUpdateReexportLoadDocumentClick(index)">
                                                            <i class="fa fa-plus"></i>
                                                        </b-button><!--/b-button-->
                                                    </div><!-- /.form-group -->
                                                </b-col><!--/b-col-->
                                                <b-col lg="2" md="2" sm="12">
                                                    <div class="form-group">
                                                        <label class="d-block">
                                                            <pre> </pre>
                                                        </label>
                                                        <b-button variant="warning" type="button" size="sm"
                                                                  class="btn-block"
                                                                  @click="resetReexportLoadDocument(index)">
                                                            {{$t('button.reset')}}
                                                        </b-button><!--/b-button-->
                                                    </div><!-- /.form-group -->
                                                </b-col><!--/b-col-->
                                            </b-row><!--/b-row-->
                                            <b-row>
                                                <b-col cols="12">
                                                    <table class="table table-bordered bg-white">
                                                        <thead>
                                                        <tr>
                                                            <th width="50">#</th>
                                                            <th width="180">{{$t('column.title')}}</th>
                                                            <th width="60">{{$t('column.action')}}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr v-for="(ct, i) in reexportLoadFormState.orders[index].documents"
                                                            :class="[{'table-primary': ct.token === reexportLoadFormState.orders[index].document.token}]">
                                                            <td>{{i + 1}}</td>
                                                            <td>
                                                                {{ct.title}}
                                                            </td>
                                                            <td>
                                                                <a @click="handleEditReexportLoadDocumentClick(index, ct.token)"
                                                                   :title="$t('button.title.editItem')"
                                                                   v-b-tooltip.hover>
                                                                    <i class="fe fe-edit"></i>
                                                                </a>
                                                                <a :title="$t('button.download')" class=" ml-1"
                                                                   :href="ct.document.download_url"
                                                                   target="_blank" v-b-tooltip.hover>
                                                                    <i class="fa fa-cloud-download"></i>
                                                                </a>
                                                                <a-popconfirm title="Are you sure？"
                                                                              @confirm="handleDeleteReexportLoadDocumentClick(index, ct.token)">
                                                                    <i slot="icon" class="fe fe-trash"></i>
                                                                    <a class=" ml-1"
                                                                       :title="$t('button.title.deleteItem')"
                                                                       v-b-tooltip.hover>
                                                                        <i class="fe fe-trash"></i>
                                                                    </a>
                                                                </a-popconfirm>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                        <tfoot
                                                            v-show="reexportLoadFormState.orders[index].documents.length <= 0">
                                                        <tr>
                                                            <th colspan="3">{{$t('title.noDataAvailable')}}</th>
                                                        </tr>
                                                        </tfoot>
                                                    </table><!-- /.table table-bordered -->
                                                </b-col><!--/b-col-->
                                            </b-row><!--/b-row-->
                                        </div><!-- /.bg-light -->
                                    </b-collapse><!-- /#reexport-load-documents-->
                                </b-card><!-- /b-card -->
                            </b-col><!--/b-col-->
                        </b-row><!--/.row-->
                        <hr/>
                    </b-col><!--/.col-->
                </b-row><!--/.row-->

                <div class="drawer-footer">
                    <b-button
                        size="sm"
                        type="submit"
                        variant="primary"
                        :disabled="global.pendingRequests > 0"
                        v-b-tooltip.hover :title="$t('button.title.save')"
                    >
                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                     size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                        <i class="fa fa-save mr-1"></i>
                        {{$t('button.save')}}
                    </b-button>
                    <b-button variant="warning" class="ml-3"
                              size="sm" @click="handleReexportLoadFormOperationClose()"
                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                    </b-button>
                </div><!--/.drawer-footer-->
            </form><!--/form-->
        </a-drawer><!--/.drawer-->
    </div><!--/div-->
</template>
<script>
    import ListingMixin from '../../../util/ListingMixin'
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../../util/Request'
    import ExcelExportMixin from '../ExcelExportMixin'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from '../detail'
    import JsonExcel from "vue-json-excel"
    import CreatePriorityMixin from "../CreatePriorityMixin";
    import CreateStatusUpdateMixin from "../CreateStatusUpdateMixin";
    import CompoundToCompoundMixin from "./CompoundToCompoundMixin";
    import CreateReexportLoadMixin from "./CreateReexportLoadMixin";
    import QuickLocationForm from "../../locations/QuickLocationForm";
    import QuickSupplierForm from "../../suppliers/QuickSupplierForm";

    const FORM_STATE = {
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        models: [],
        configCode: [],
        dealers: [],
        order_countries: [],
        clients: [],
        orderTransportStatus: [],
        transportRequestType: [],
        suppliers: [],
        status: [8, 9],
        status_additional_info: null,
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.supplierCompound'),
            key: 'supplier_compound_id',
            sortable: true,
            stickyColumn: true,
        },
        {
            label: self.$t('column.brand'),
            key: 'brand_id',
            sortable: true,
        },
        {
            label: self.$t('column.vin'),
            key: 'vin_number',
            sortable: true,
        },
        {
            label: self.$t('column.model'),
            key: 'model_id',
            sortable: true,
        },
        {
            label: self.$t('column.market'),
            key: 'order_country',
            sortable: true,
        },
        {
            label: self.$t('column.gateIn'),
            key: 'gate_in_date',
            sortable: false,
        },
        {
            label: self.$t('column.dealer'),
            key: 'dealer_id',
            sortable: true,
        },
        {
            label: self.$t('column.status'),
            key: 'load_status',
            sortable: true,
        },
        {
            label: self.$t('column.logisticType'),
            key: 'logistic_type',
            sortable: false,
        },
        {
            label: self.$t('column.modifiedAt'),
            key: 'updated_at',
            sortable: true,
        },
        (self.$global.hasAnyPermission(['ordersownview'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right',
                key: 'action',
            } : {}),
    ];

    export default {
        mixins: [ListingMixin, ExcelExportMixin, CompoundToCompoundMixin, CreatePriorityMixin, CreateStatusUpdateMixin, CreateReexportLoadMixin],
        components: {
            Treeselect,
            Detail,
            JsonExcel,
            QuickLocationForm,
            QuickSupplierForm
        },
        data() {
            return {
                operationTitle: 'title.stock',
                formFields: {...FORM_STATE},
                formErrors: new Error({}),
                filters: {...FILTER_STATE},
                listUrl: 'orders',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    countries: [],
                    clients: [],
                    brands: [],
                    models: [],
                    configCode: [],
                    dealers: [],
                    dealersLocations: [],
                    supplierCarriers: [],
                    supplierCompounds: [],
                    locations: [],
                    currencies: [],
                    status: [
                        {id: -3, label: this.$t('title.pending')},
                        {id: -2, label: this.$t('title.qualityHoldBlocked')},
                        {id: -1, label: this.$t('title.confirmationOrder')},
                        {id: 1, label: this.$t('title.produced')},
                        {id: 2, label: this.$t('title.addedToLoad')},
                        {id: 3, label: this.$t('title.assignedToLoad')},
                        {id: 4, label: this.$t('title.loaded')},
                        {id: 5, label: this.$t('title.inTransit')},
                        {id: 6, label: this.$t('title.unloadRequested')},
                        {id: 7, label: this.$t('title.unloaded')},
                        {id: 8, label: this.$t('title.stocked')},
                        {id: 9, label: this.$t('title.hold')},
                        {id: 10, label: this.$t('title.transportOrder')},
                        {id: 11, label: this.$t('title.announced')},
                        {id: 12, label: this.$t('title.rejected')},
                        {id: 13, label: this.$t('title.confirmed')},
                        {id: 14, label: this.$t('title.pickUp')},
                        {id: 15, label: this.$t('title.stockExitInTransit')},
                        {id: 16, label: this.$t('title.deliveryRequested')},
                        {id: 17, label: this.$t('title.delivered')},
                    ],
                    statusAdditionalInfo: [
                        {id: -1, label: this.$t('title.showOnlyStockOrders')},
                        {id: 1, label: this.$t('title.requested')},
                        {id: 2, label: this.$t('title.rejected')},
                        {id: 3, label: this.$t('title.confirmed')},
                        {id: 4, label: this.$t('title.clientAffected')},
                        {id: 5, label: this.$t('title.deliveryRequest')},
                        {id: 6, label: this.$t('title.readyToBeLoaded')},
                        {id: 7, label: this.$t('title.serviceOrders')},
                        {id: 8, label: this.$t('title.damaged')},
                        {id: 9, label: this.$t('title.serviceOrders') + ' | ' + this.$t('title.damaged')},
                    ],
                    priorities: [
                        {id: 1, label: this.$t('title.high')},
                        {id: 2, label: this.$t('title.medium')},
                        {id: 3, label: this.$t('title.low')}
                    ],
                },
                show: true,
                priorityFormVisible: false,
                statusFormVisible: false,
                ccRequestStatusFormVisible: false,
                selectedOrderLength: 0,
                selectedOrders: [],
                ctcLoadFormVisible: false,
                selectedCtcOrderLength: 0,
                groupedCtcOrders: [],
                reexportLoadFormVisible: false,
                groupedReexportOrders: [],
                selectedReexportOrderLength: 0,
            }
        },
        mounted() {
            this.getClients();
            this.getDealers();
            this.getBrands();
            this.getModels();
            this.getCountries();
            this.getLocations();
            this.getSupplierCarriers();
            this.getCurrencies();
            this.getSupplierCompounds();

            if (this.$route.query && this.$route.query.operation && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }

            this.$title = this.$t('title.stock')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            handleRowClick(item, rowIndex, event) {
                this.handleLoadRowClick(item, rowIndex, event)
                this.handleReexportRowSelection(item, rowIndex, event)
                this.handleCtcRowSelection(item, rowIndex, event)
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data
                    this.dropdowns.configCode = data.map(item => ({
                        id: item.version_code,
                        label: item.version_code,
                        model_id: item.id
                    }))

                } catch (e) {
                    this.dropdowns.models = []
                    this.dropdowns.configCode = []
                }
            },
            async getClients() {
                try {
                    const response = await request({
                        url: '/dropdowns/clients',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.clients = data

                } catch (e) {
                    this.dropdowns.clients = []
                }
            },
            async getDealers() {
                try {
                    const response = await request({
                        url: '/dropdowns/dealers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.dealers = data
                } catch (e) {
                    this.dropdowns.dealers = []
                    this.dropdowns.dealersLocations = []
                }
            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.countries = data

                } catch (e) {
                    this.dropdowns.countries = []
                }
            },
            async getSupplierCarriers() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/carrier',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCarriers = data

                } catch (e) {
                    this.dropdowns.supplierCarriers = []
                }
            },
            async getSupplierCompounds() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/compound',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCompounds = data

                } catch (e) {
                    this.dropdowns.supplierCompounds = []
                }
            },
            async getCurrencies() {
                try {
                    const response = await request({
                        url: '/dropdowns/currencies',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.currencies = data
                } catch (e) {
                    this.dropdowns.currencies = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data
                } catch (e) {
                    this.dropdowns.locations = []
                }
            },
            handleSelectModelsSelect(changeValue) {
                this.filters.configCode = []
            },
            hasListAccess() {
                return this.$global.hasPermission('ordersview')
            },
            refreshList() {
                this.loadList()
                this.handlePriorityFormOperationClose(false)
                this.handleReexportLoadFormOperationClose(false)
                this.handleCtcLoadFormOperationClose(false)
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80 {
        width: 80px;
    }

    .w-220 {
        width: 220px;
    }

    .in-center {
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    @media screen and (max-width: 1379px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }
</style>
